html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  -webkit-tap-highlight-color: transparent;
}

.App-logo, .App-logo-text, #comingSoon, .portfolioLogo {
  transition: 1s ease;
}

.landingDiv {
  height: 95%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.portfolioLogo {
  cursor: pointer;
  height: 5vmin;
  width: 5vmin;
  position: fixed;
  bottom: -45px;
  margin-left: -2.5vmin;
  opacity: 0;
}
.portfolioLogo.reveal {
  bottom: 10px;
  opacity: 50%;
}
.portfolioLogo:hover {
  opacity: 100%;
}

.comingSoon {
  z-index: 0;
  position: fixed;
  font-family: Couture, sans-serif;
  margin-top: 0vmin;
  opacity: 0;
  color: #8f8f8f;
  font-size: 3.5vmin;
  pointer-events: none;
}
.comingSoon.reveal {
  margin-top: 35vmin;
  letter-spacing: 3.5vmin;
  margin-right: -3.5vmin;
  opacity: 100%;
}

.noOfSpins {
  font-size: 3vmin;
  width: 100%;
  text-align: center;
  font-family: Couture, sans-serif;
  color: #4f4f4f;
  position: fixed;
  opacity: 0;
  margin-top: -5vmin;
  transition: 2s ease-in-out;
}
.noOfSpins.reveal {
  width: 100%;
  letter-spacing: 0.75vmin;
  text-align: center;
  position: fixed;
  opacity: 100%;
  margin-top: 5vmin;
}

.App {
  height: 100%;
  text-align: center;
  background: black;
}

.App-logo {
  height: 55vmin;
  z-index: 2;
  cursor: pointer;
  pointer-events: auto !important;
  margin-right: 15vmin;
  animation: gradient 15s ease infinite, pulse 4s ease-in-out infinite;
}
.App-logo.reveal {
  height: 27vmin;
  margin-right: 0;
  animation: gradient 15s ease infinite, spinReveal 1s ease;
  /*animation: gradient 15s ease infinite;*/
}
.App-logo.hide {
  margin-right: 15vmin;
  animation: gradient 15s ease infinite, spinHide 1s ease;
  /*animation: gradient 15s ease infinite;*/
}
.App-logo:active {
  opacity: 20%;
}
.App-logo:active {
  opacity: 20%;
}

.App-logo-text {
  height: 27vmin;
  pointer-events: none;
  background-size: 400% 400%;
}

.App-logo-text {
  z-index: 1;
  margin-left: -50vmin;
  margin-right: -35vmin;
  height: 15.2vmin;
  opacity: 0;
  animation: gradient 15s ease infinite;
}
.App-logo-text.reveal {
  margin-left: -2vmin;
  margin-right: -3.5vmin;
  height: 15.2vmin;
  opacity: 100%;
}
.App-logo.enlarge {
  height: 95vmin;
}

.logoDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@keyframes gradient {
  0% {
    filter: hue-rotate(1deg);
  }
  50% {
    filter: hue-rotate(360deg);
  }
  100% {
    filter: hue-rotate(1deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1);
  }
  44% {
    transform: scale(1.025);
  }
  48% {
    transform: scale(1);
  }
  53% {
    transform: scale(1);
  }
  57% {
    transform: scale(1.025);
  }
  61% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spinReveal {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinHide {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
    animation: pulse 4s ease-in-out infinite;
  }
}