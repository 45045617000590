@media only screen and (max-width: 1024px) {
    ::selection {
        color: rgba(0, 0, 0, 0);
        background: none;
    }
    ::-moz-selection {
        color: rgba(0, 0, 0, 0);
        background: none;
    }

    .App-logo {
        height: 70vmin;
    }
    .App-logo.reveal {
        height: 24.2vmin;
    }

    .App-logo-text {
        margin-left: -60vmin;
        margin-right: -45vmin;
        height: 13.5vmin;
        opacity: 0;
        animation: gradient 15s ease infinite;
    }
    .App-logo-text.reveal {
        margin-left: -1.5vmin;
        margin-right: -3vmin;
        height: 13.5vmin;
        opacity: 100%;
    }

    .comingSoon {
        position: fixed;
        margin-top: 5vmin;
    }
    .comingSoon.reveal {
        margin-top: 35vmin;
    }


    .landingDiv {
        height: 95%;
    }

    .portfolioLogo {
        height: 10vmin;
        width: 10vmin;
        margin-left: -5vmin;
    }
}
